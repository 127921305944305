import React from "react"
import {WorkoutSpec} from "../data/WorkoutSpec";
import {WorkoutApp} from "../components/workoutApp";

interface Props {
    pageContext: {
        allWorkouts: {
            workouts: WorkoutSpec[]
        }
    }
}

export default class AppTemplate extends React.Component<Props> {
    render() {
        const allWorkouts = this.props.pageContext.allWorkouts["workouts"]
        return (
            <WorkoutApp data={allWorkouts}/>
        )
    }
}
