import React from 'react';
import {GridRow, Header} from 'semantic-ui-react';

import {WorkoutList} from './workouts/workoutList'
import {Filter} from './workouts/workoutFilter'
import {WorkoutSpec} from "../data/WorkoutSpec";

interface Props {
    readonly data?: WorkoutSpec[]
}

export const WorkoutApp = (props: Props) => {
    let content =
        <div>
            <GridRow>
                <Header as='h1' textAlign='center'>
                    Create your custom workout
                </Header>
                <Filter/>
            </GridRow>
            <br/>
            <GridRow>
                <WorkoutList data={props.data}/>
            </GridRow>
        </div>

    return (content)
}
