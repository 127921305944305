import React from 'react'
import {WorkoutCard} from "./workoutCard";
import {Card, Grid, Message, Icon, Container} from 'semantic-ui-react';
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {WorkoutSpec} from "../../data/WorkoutSpec";

export interface WorkoutListFilter {
    selectedType: string,
    selectedTarget: string,
    selectedDifficulty: string,
    selectedFocus: string,
    selectedDuration: string,
    selectedVariety: string
}


interface Props {
    readonly data?: WorkoutSpec[]
}

export const WorkoutList = (props: Props) => {
    const {workouts, isLoading} = useSelector(
        (state: RootState) => state.workouts
    )


    let defaultWorkouts: WorkoutSpec[] =  workouts.length > 0
        ? workouts
        : props.data != null ? props.data : []

    let workoutCards = defaultWorkouts.map(workout => {
        return <WorkoutCard workoutData={workout}/>
    })


    return isLoading
        ? showFetching()
        :<Container>
            <h2 className="ui center aligned icon header">
                <i className="circular heart outline icon"/>
                Workouts
            </h2>
            <br/>
            <Grid container><Card.Group centered={true}>{workoutCards}</Card.Group></Grid>
        </Container>
}


const showFetching = () => (
    <Message icon>
        <Icon name='circle notched' loading />
        <Message.Content>
            <Message.Header>Loading</Message.Header>
            We are fetching your workouts.
        </Message.Content>
    </Message>
)

