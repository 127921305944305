import React, {useState} from 'react';

import {
    targetFilterValues, difficultyFilterValues,
    focusFilterValues, durationFilterValues,
    varietyFilterValues} from '../../data/longFiltersData';

import {WorkoutSpec} from "../../data/WorkoutSpec";
import {Segment, Grid, Dropdown, GridColumn, DropdownProps} from 'semantic-ui-react';
//import ToggleButton from './ToggleButton.js'
import {filterWorkoutsTest} from "../../store/WorkoutSlice"
import {useDispatch} from "react-redux";
import {WorkoutListFilter} from "./workoutList";


type InputEvent = React.SyntheticEvent<HTMLElement>
type ChangeHandler = (e: InputEvent, d: DropdownProps) => void

export const Filter = ()=> {
    const dispatch = useDispatch()

    let emptyWorkout: WorkoutSpec = {
        description: "",
        title: "",
        youtube_id: "",
        id: "",
        type: "",
        variety: "",
        focus: "",
        duration: "",
        target: "",
        difficulty: ""
    }

    const [workout, setWorkout] = useState(emptyWorkout)
    const onDropDownChanged: ChangeHandler = (e,d) => {
        const { name, value } = d
        let newWorkout = {...workout}
        newWorkout[name] = value
        setWorkout(newWorkout)
        updateExercises(newWorkout)
    }

    const updateExercises = (workoutSpec: WorkoutSpec) => {
        let workoutRequest: WorkoutListFilter = {
            selectedDifficulty: workoutSpec.difficulty,
            selectedDuration: workoutSpec.duration,
            selectedFocus: workoutSpec.focus,
            selectedTarget: workoutSpec.target,
            selectedType: workoutSpec.type,
            selectedVariety: workoutSpec.variety
        }
        dispatch(filterWorkoutsTest(workoutRequest))
    }

    return (
        <Grid stackable={true} columns={5}>
            <GridColumn>
                <Segment>
                    <h5>Target body area</h5>
                    <Dropdown
                        name='target'
                        label="Body area"
                        defaultValue={"*"}
                        fluid
                        selection
                        options={targetFilterValues}
                        onChange={onDropDownChanged}
                    />
                </Segment>
            </GridColumn>

            <GridColumn>
                <Segment>
                    <h5>Workout focus </h5>
                    <Dropdown
                        placeholder='Training focus'
                        name='focus'
                        label="Focus"
                        defaultValue={"*"}
                        fluid
                        selection
                        options={focusFilterValues}
                        onChange={onDropDownChanged}
                    />
                </Segment>
            </GridColumn>
        <GridColumn>
                <Segment>
                    <h5>Difficulty </h5>
                    <Dropdown
                        placeholder='Choose 1-5'
                        name='difficulty'
                        label="Difficulty"
                        defaultValue={"*"}
                        fluid
                        selection
                        options={difficultyFilterValues}
                        onChange={onDropDownChanged}
                    />
                </Segment>
            </GridColumn>

            <GridColumn>
                <Segment>
                    <h5>Duration </h5>
                    <Dropdown
                        placeholder='Choose 5-60 min'
                        name='duration'
                        label="Duration"
                        defaultValue={"*"}
                        fluid
                        selection
                        options={durationFilterValues}
                        onChange={onDropDownChanged}
                    />
                </Segment>
            </GridColumn>
        <GridColumn>
                <Segment>
                    <h5>Variety </h5>
                    <Dropdown
                        placeholder='Low or high numbers of reps per exercise'
                        name='variety'
                        label="Variety"
                        defaultValue={"*"}
                        fluid
                        selection
                        options={varietyFilterValues}
                        onChange={onDropDownChanged}
                    />
                </Segment>
            </GridColumn>
        </Grid>
    )
}
