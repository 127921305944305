export const targetFilterValues = [
    {
        id: 1,
        value: "core",
        text: "Abs, obliques, lower back"
    },
    {   id: 2,
        value: "lower",
        text: "Thighs, butt, calves, legs"
    },
    {
        id: 3,
        value: "full",
        text: "All muscle groups"
    },
    {
        id: 4,
        value: "*",
        text: "Any",
    }
];

export const difficultyFilterValues = [
    {
        id: 1,
        value: "sweaty",
        text: "Sweaty",
    },
    {
        id: 2,
        value: "beast",
        text: "Beast",
    },
    {
        id: 3,
        value: "*",
        text: "Any",
    }
];

export const focusFilterValues = [
    {
        id: 1,
        value:"cardio",
        text:"Cardio & HIIT"
    },
    {
        id: 2,
        value:"lowImpact",
        text:"Low impact"
    },
    {
        id: 3,
        value:"power",
        text:"Strengthening"
    },
    {
        id: 4,
        value: "*",
        text: "Any"
    }
]

export const durationFilterValues = [
    {
        id: 1,
        value:"10",
        text:"10 min",
    },
    {
        id: 2,
        value:"20",
        text:"20 min",
    },
    {
        id: 3,
        value: "*",
        text: "Any"
    }
]

export const styleFilterValues = [
    {
        id: 1,
        value:"0",
        text:"Pick-me-up"
    },
    {
        id: 2,
        value:"1",
        text:"Quick workout"
    },
    {
        id: 3,
        value:"2",
        text:"Full workout"
    },
    {
        id: 4,
        value:"3",
        text:"Restorative"
    },
    {
        id: 5,
        value: "",
        text: "Any"
    }
]

export const varietyFilterValues = [
    {
        id: 1,
        value:"balanced",
        text:"Lots of reps"
    },
    {
        id: 2,
        value:"stamina",
        text:"Balanced"
    },
    {
        id: 3,
        value:"bored",
        text:"No repeated exercises"
    },
    {
        id: 4,
        value: "*",
        text: "Any"
    }
]
