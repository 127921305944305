import React from 'react'
import {WorkoutSpec} from '../../data/WorkoutSpec'
import {Card, Image, List, Button, Grid} from 'semantic-ui-react';
import {navigate} from "gatsby";


interface Props {
    workoutData: WorkoutSpec
}
//key?
export const WorkoutCard = ({workoutData}: Props) => {
    let thumbnailSrc = 'https://i3.ytimg.com/vi/' + workoutData.youtube_id + '/maxresdefault.jpg'

    /*
    const history = useHistory();
    const navigateToWorkout = (youtube_id: String) => {
        history.push("/player/" + youtube_id)
    }
     */

    const navigateToWorkout = (youtube_id: String) => {
        navigate('/do/'+youtube_id)
    }

    return (
            <Card>
                <Card.Content>
                    <Image
                        floated='right'
                        src={thumbnailSrc}
                    />
                    <Card.Header>
                        {workoutData.title}
                    </Card.Header>
                    <br/>
                    <Card.Meta>
                        <List>
                            <List.Item>
                                <List.Icon name='star' />
                                <List.Content>Level: {workoutData.difficulty}</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name='star' />
                                <List.Content>Variety: {workoutData.variety}</List.Content>
                            </List.Item>
                        </List>
                    </Card.Meta>
                </Card.Content>
                <Card.Content extra>
                    <Grid  columns={1} >
                            <Grid.Column textAlign="center">
                                <Button
                                    basic
                                    color='teal'
                                    onClick={e=>navigateToWorkout(workoutData.youtube_id)}>
                                    Start
                                </Button>
                            </Grid.Column>
                    </Grid>
                </Card.Content>
            </Card>
    )
}
